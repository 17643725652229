#invitatie .card {
    background-image: linear-gradient(90deg, black , #134b30 90%);
    color: rgb(112, 105, 9);
}

#invitatie .card h4 {
    color: gold;
    text-shadow: 1px 1px 2px rgb(26, 25, 25);
    
}

#img_noi {
    width: 250px;
    border-radius: 100%;
}

#img_nasi {
    width: 250px;
    border-radius: 100%;
}

.music {
    width: 100px;
}